<template>
  <div class="purchase-body">
    <h2 class="marketplace-title">{{ $t('mainMarketplaceTitle') }}</h2>
    <div class="grid-wrapper">
      <SaleStatus :key="version" />

      <div class="shop-wrapper">
        <div class="offers-wrapper card main-cards">
          <h4 class="offers-title">{{ $t('mainTortugaMarketplaceTitle') }}</h4>
          <MyTokens :has-title="false" :show-sellable="false" />
          <div class="offers-list">
            <BuyPackButton
              v-for="pack in packs"
              :key="pack.key"
              :pack-key="pack.key"
              :price="pack.price"
              :token-reward="pack.tokenReward"
              :extra-rewards="pack.extraRewards"
              :extra-reward-key="pack.extraRewardKey || ''"
              :name="pack.name"
              :image-path="pack.imagePath"
              :token="pack.token"
              :pack-description-key="pack?.descriptionKey"
              @success="updateData"
            />
          </div>
          <template v-if="store.currentToken === Tokens.pearl">
            <p class="text-block">
              {{ $t('marketplacePearlTextBlock1') }}
            </p>
            <p class="text-block">
              {{ $t('marketplacePearlTextBlock2') }}
            </p>
          </template>
          <template v-else>
            <p class="text-block">
              {{
                $t('marketplaceOtherTokenTextBlock1', {
                  token: store.currentToken.toUpperCase(),
                  tokenDeclensed: $t(tokenConfig.label, { count: 2 }).toLocaleLowerCase()
                })
              }}
            </p>
            <p class="text-block">
              {{
                $t('marketplaceOtherTokenTextBlock2', {
                  token: store.currentToken.toUpperCase(),
                  tokenDeclensed: $t(tokenConfig.label, { count: 2 }).toLowerCase(),
                  tokenSingle: $t(tokenConfig.label).toLowerCase()
                })
              }}
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useHeadSafe } from '@unhead/vue';
import { ref } from 'vue';
import {
  COAL_PACK_DATA,
  GOLD_ORE_PACK_DATA,
  IRON_ORE_PACK_DATA,
  PEARL_PACK_DATA,
  WOOD_PACK_DATA
} from '~/utils/constants/marketplace';
import { useI18n } from '#imports';
import { Tokens, tokensConfig } from '~/utils/constants';
import { useMainStore } from '#imports';

const store = useMainStore();
const { t } = useI18n();

const packs = computed(() => {
  return tokenSwitchHandler(store.currentToken).sort((a, b) => b.price - a.price);
});

const tokenConfig = computed(() => {
  return tokensConfig[store.currentToken];
});

const tokenSwitchHandler = (token: string) => {
  switch (token) {
    case Tokens.pearl:
      return PEARL_PACK_DATA;
    case Tokens.coal:
      return COAL_PACK_DATA;
    case Tokens.goldOre:
      return GOLD_ORE_PACK_DATA;
    case Tokens.ironOre:
      return IRON_ORE_PACK_DATA;
    case Tokens.wood:
      return WOOD_PACK_DATA;
    case Tokens.gold:
      return [];
    default:
      return PEARL_PACK_DATA;
  }
};

useHeadSafe({
  title: t('mainIndexTitle')
});
const version = ref(0);

function updateData() {
  version.value = version.value++;
}
</script>

<style scoped lang="scss">
.text-container {
  padding: 0 22px;
}
.text-block {
  @media screen and (max-width: 990px) {
    text-align: center;
  }
}
.purchase-body {
  display: flex;
  justify-content: center;
  gap: 37px;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 60px;

  @media screen and (max-width: 479px) {
    margin-top: 0;
  }

  @media screen and (max-width: 900px) {
    gap: 0;
  }

  @media screen and (max-width: 767px) {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

.marketplace-title {
  color: #fff;
  font-size: 64px;
  margin: 0;

  @media screen and (max-width: 990px) {
    margin-top: 35px;
    margin-bottom: 35px;
    font-size: 3em;
  }
}

.offers-list {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  .card {
    width: 270px;
  }
}

.card {
  overflow: hidden;
  padding: 6px 44px;
}
.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-auto-columns: 1fr;
  display: grid;
}

.offers-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--main-text-color);
  font-weight: 700;
  font-family: Grenze, sans-serif;
}

.offers-wrapper {
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid var(--blue-2);
  background: var(--blue-3);
  margin-bottom: 20px;
}

.sale-wrapper,
.shop-wrapper {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

.offer {
  display: flex;
  align-items: baseline;
  transition: ease all 200ms;
  cursor: pointer;
}

.card.pd-48px---32px {
  padding: 0 10px;
}

.card.pd-48px---32px.shop-item {
  color: rgba(255, 255, 255, 0.5);
  background-color: #021b26;
  background-image: radial-gradient(circle closest-corner at 50% 0, #3b7285, rgba(0, 0, 0, 0));
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: none;
  transition:
    all 0.3s,
    color 0.3s;

  &:hover {
    background-image: radial-gradient(circle closest-corner at 50% 0, #3faa58, rgba(0, 0, 0, 0));
  }
}

@media screen and (max-width: 479px) {
  .text-block {
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .card.pd-48px---32px.shop-item {
    background-image: radial-gradient(circle farthest-side at 50% 0, #3b7285, rgba(0, 0, 0, 0) 50%);
    flex-direction: column;
    align-items: stretch;
    padding: 16px 20px;
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .card.pd-48px---32px.shop-item {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 479px) {
  .card.pd-48px---32px.shop-item {
    padding: 12px 8px;
    font-size: 12px;
  }
}

.grid-wrapper {
  grid-column-gap: 28px;
  grid-row-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

@media screen and (max-width: 991px) {
  .grid-wrapper {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .grid-wrapper {
    flex-direction: column;
    display: flex;
  }
}
</style>
